@import "styles/colors.css";

@font-face {
    font-family: 'TT Firs';
    src: local('TT Firs'), url(./TTFirsNeue-Regular.e009cf5.woff2) format('woff');
}

.Account-container {
    margin: 12pt;
    padding: 6pt;
    border: 1px solid antiquewhite;
    border-radius: 4pt;
    background: antiquewhite;
}

.Account-prop-title {
    padding-left: 6pt;
    padding-right: 6pt;
    color: darkgoldenrod;
    font-size: x-small;
    text-transform: lowercase;
}

.Account-prop-value {
    padding: 4pt 6pt 2pt;
    color: lightslategray;
    border-bottom: 1px dotted burlywood;
}

.Register-card {
    background-color: aliceblue !important;
}

.ec-app-footer {
    /*background: var(--footer-bg);*/
    color: var(--footer-color);
}

.modal-content {
    background: var(--card-bg) !important;
    color: var(--text-color) !important;
}

.modal-header {
    border-bottom: var(--separator-border) !important;
    color: var(--card-header-color);
}

.modal-footer {
    border-top: var(--separator-border) !important;
}

.ec-card {
    background: var(--card-bg) !important;
    border-radius: 12pt !important;
    border: none !important;
}

.ec-sub-card {
    background: var(--card-list-selected-item-bg) !important;
    border-radius: 4pt !important;
    border: none !important;
}

.ec-centered-card {
    background: var(--card-bg) !important;
    border-radius: 12pt !important;
    border: none !important;
    /*max-width: 30rem;*/
    margin: 0;
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.ec-card-list-item {
    border: none !important;
    color: var(--link-color) !important;
    background: none !important;
    margin: 0 !important;
    cursor: pointer;
    max-width: 16em;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.ec-card-list-selected-item {
    border: none !important;
    border-radius: 8pt !important;
    border-right: none !important;
    color: var(--card-list-selected-item-color) !important;
    background: var(--card-list-selected-item-bg) !important;
    margin: 0 !important;
    max-width: 16em;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
}

.ec-card-header {
    font-size: x-large;
    color: var(--card-header-color);
    font-weight: bold;
}

.ec-card-body {
    color: var(--text-color) !important;
}

.ec-card-code {
    width: 100%;
    overflow: scroll;
    color: var(--code-color) !important;
    background: var(--code-bg);
    padding: 8pt;
    white-space: pre-wrap;
}

.ec-card-list {
}

.ec-card-footer {
    padding: 0;
}

.ec-card-topup {
    font-size: smaller;
    padding: 3pt 6pt;
}

.ec-card-upgrade {
    font-size: smaller;
    padding: 3pt 6pt;
}

.ec-card-link {
    text-decoration: none;
    color: var(--link-color);
}

.ec-tab-selected {
    border-left: none !important;
    border-top: none !important;
    border-bottom: 3pt solid var(--tab-selected-line-color) !important;
    border-right: none !important;
    border-radius: 0 !important;
    color: var(--tab-selected-color) !important;
    background: none !important;
    margin: 0 !important;
    padding: 6pt 8pt !important;
    cursor: default !important;
}

.ec-tab-normal {
    border-left: none !important;
    border-right: none !important;
    border-bottom: none !important;
    border-top: none !important;
    background: none !important;
    color: var(--link-color) !important;
    cursor: pointer !important;
    margin: 0 !important;
    padding: 6pt 8pt !important;
}

.ec-form-field input {
    background: var(--form-field-bg) !important;
    border-color: var(--form-field-border-color) !important;
    color: var(--form-field-color) !important;
}

.ec-form-field select {
    background: var(--form-field-bg) !important;
    border-color: var(--form-field-border-color) !important;
    color: var(--form-field-color) !important;
}

.ec-form-field label {
    color: var(--form-field-color) !important;
}

.ec-form-field label::after {
    content: none !important;
    background: none !important;
}

.form-switch input[type=checkbox] {
    background-color: var(--form-field-bg);
}

.form-switch input[type=checkbox]:checked {
    background-color: var(--dashboard-filter-radio);
}

