.appHeader {

  &__container {
    width: 100%;
    margin: 0 0 8px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    background-color: var(--card-bg) !important;
  }

  &__announcementBar {
    display: flex;
    justify-content: center;
    background-color: #f8ba3c;
    text-align: center;

    &__title {
      font-size: 1em;
      color: #444 !important;
      padding: 4pt 4pt;
      font-weight: 500;
      text-align: center;

      a {
        text-decoration: none;
      }
    }
  }

  &__navSettings {
    display: flex;
    align-items: center;
  }

  &__title {
    font-size: 1em;
    color: var(--card-header-color) !important;
    padding: 8pt 8pt;
  }

  &__theme {
    padding: 8pt 8pt;
    border: none !important;
    background: none !important;
    font-size: 22pt;
  }

  &__titleLink {
    text-decoration: none !important;
    color: var(--card-header-color) !important;
    font-size: x-large;
    font-weight: bold !important;
  }

  &__navLink {
    text-decoration: none !important;
    color: var(--card-header-color);
    align-items: stretch;
    margin: 0 12px -1px;
    &:hover {
      color: var(--card-header-color-active);
    }
  }

  &__ovalNavLink {
    text-decoration: none !important;
    color: #fff !important;
    background-color: #f8ba3c;
    border: none !important;
    border-radius: 20px !important;
    padding: 8px 13px;
    margin-right: 8px;
    font-weight: 700 !important;
    white-space: nowrap;

    &:active{
      background-color: #ce9834;
      transition: background-color 0.15s ease-in-out 0s;
    }
  }
}
