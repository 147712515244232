.Explorer {
  display: flex;
  flex-direction: column;
  row-gap: 15px;

  max-width: 1320px;
  margin: 0 auto;
  padding: 15px;

  &__logs {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 20px;
  }
}
