@import "src/styles/mixins";

.DashboardSidebar {
  display: flex;
  flex-direction: column;
  gap: 20px;

  height: max-content;

  padding: 16px;

  @include tabletWidthAndUp {
    width: 250px;
  }

  & > * {
    overflow: hidden;
  }

  & > * div div {
    max-width: none;
  }

  & > * div a {
    max-width: none;
  }
}
