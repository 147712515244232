.TimeRangePicker {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 16px 10px;

  &__closeIcon {
    position: sticky;
    height: 0;
    top: 20px;
    right: 20px;

    align-self: flex-end;

    z-index: 10000;
    cursor: pointer;
  }

  &__body {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 30px;
  }

  &__footer {
    display: flex;
    justify-content: center;
    width: max-content;

    background-color: #e1e8ed;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 5px 20px;
    font-size: 16px;
  }
}
