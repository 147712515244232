.ProjectEndpoints__body {
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    overflow: hidden;
}

.Network {
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  &__endpoints {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }

  &__endpoint {
    display: flex;
    column-gap: 10px;

    .endpoint {
      &__text {
        text-overflow: ellipsis;
        white-space: nowrap;

        overflow: hidden;
      }

      &__copyWrapper {
        margin-left: auto;
      }
    }
  }
}

.NetworkTitle {
  display: flex;
  align-items: center;
  column-gap: 10px;

  &__networkIcon {
    width: 22px;
  }

  &__text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    font-size: larger;
    font-weight: bold;
    color: var(--title-color);
    word-break: break-all;

    overflow: hidden;
  }

  &__giverButton {
    display: flex;

    width: 32px;
    height: 32px;

    &_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 100%;
      height: 100%;
    }

    img {
      min-width: 20px;
      background: transparent;
    }
  }
}

.PlaygroundLink {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 32px;
  height: 32px;

  text-decoration: none;
  color: white;
  background-color: #df0081;

  border-radius: 4px;

  cursor: pointer;

  img {
    width: 22px;
  }
}
