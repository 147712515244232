.Filters {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  flex: 1 0 230px;

  &__section {
    background-color: var(--card-bg) !important;
    border: var(--separator-border);
    box-shadow: rgb(0, 0, 0, 0.05) 0 5px 10px;
    border-radius: 0.25rem !important;
    &:before {
      display: none;
    }

    & > div > div> svg {
      color: var(--dashboard-filter-title) !important;
    }

    .section {
      &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: var(--dashboard-filter-title);
        text-transform: uppercase;
        font-size: 12px;
        padding: 6px 12px;
        cursor: pointer;
        min-height: auto !important;
      }

      &__content {
        margin-top: 5px;
        margin-bottom: 5px;
      }

      &__duraiton {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
      }
    }
  }

  &__radio {
    color: var(--text-color) !important;

    &__checked {
      color: var(--dashboard-filter-radio) !important;
    }
  }

  &__label {
    color: var(--text-color) !important;
  }

  &__field {
    input {
      color: var(--text-color) !important;
    }

    fieldset {
      border-color: var(--dashboard-filter-title) !important;
    }
  }
}
