.DateTimePicker {
  &.MuiPickerStaticWrapper-root {
    font: {
      weight: 500;
      style: normal;
      size: 21px;
    }
    line-height: 24px;
    letter-spacing: -0.22px;
  }

  .MuiDialogActions-root {
    display: none;
  }

  .MuiPickersToolbar-root ~ div {
    @media (max-width: 400px) {
      width: 100%;
    }
  }

  .MuiPickersToolbar-penIconButton {
    display: none;
  }

  .MuiDateTimePickerToolbar-timeContainer span {
    font-size: 30px;
  }

  .MuiPickersDay-root {
    font-size: 17px;
  }

  .MuiTypography-caption {
    font: {
      weight: 600;
      style: normal;
      size: 13px;
    }
    line-height: 20px;
    letter-spacing: -0.04px;

    &:has {
      justify-content: space-between;
    }
  }

  .MuiCalendarPicker-root {
    @media (max-width: 400px) {
      width: 100%;
    }

    & > div {
      &:first-child {
        padding: 0;
      }

      &:last-child > div:first-child {
        justify-content: space-between;
      }
    }
  }

  .PrivatePickersFadeTransitionGroup-root > div > div:first-child {
    justify-content: space-between;
  }

  .PrivatePickersSlideTransition-root {
    & [role="row"] {
      justify-content: space-between;
    }
  }

  .MuiPickerStaticWrapper-content {
    min-width: auto;
  }

  .MuiDateTimePickerToolbar-root > .MuiTypography-root {
    font-weight: 800;
    font-size: 18px;
  }

  .MuiPickersToolbar-content {
    column-gap: 20px;
    align-items: flex-end;
  }

  .MuiDateTimePickerToolbar-dateContainer button:last-child span {
    font-size: 30px;
  }
}
