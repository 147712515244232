@import "src/styles/mixins";

.ProjectList {
  &__list {

  }

  &__addProjectButton {
    width: 180px;
    align-self: center;

    @include tabletWidthAndUp {
      width: auto;
      align-self: stretch;
    }
  }
}
