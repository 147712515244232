.LogsBody {
  position: relative;

  display: flex;
  flex-direction: column;
  row-gap: 20px;

  flex: 15 1 650px;

  overflow: hidden;

  &__data {
    background-color: var(--card-bg);

    border-radius: 4px 4px 0 0;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background: rgba(0, 0, 0, 0.1);
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: rgba(0, 0, 0, 0.2);
    }
    &::-webkit-scrollbar-thumb:hover {
      background: rgba(0, 0, 0, 0.4);
    }
    &::-webkit-scrollbar-thumb:active {
      background: rgba(0, 0, 0, 0.9);
    }

    .data {
      &__row {
        display: flex;
        width: max-content;
        font-size: 13px;
        border-bottom: 1px solid var(--card-header-color);
        color: var(--form-field-color);

        &:not(.data__row_header) div {
          text-align: center;

          &:not(:last-child) {
            border-right: 1px solid var(--card-header-color);
          }
        }

        & > div {
          flex: 0 0 auto;
          text-overflow: ellipsis;
          padding: 5px 10px;

          p {
            margin: 0;
          }
        }

        &_header {
          align-items: center;
          display: flex;
          height: 32px;

          background-color: #f8ba3c;

          text-transform: uppercase;
          white-space: nowrap;
          color: var(--dashboard-logs-header);
          font-size: 12px;
          font-weight: 500;

          border-radius: 4px 4px 0 0;

          & > div, p {
            text-align: center;
            flex: 0 0 auto;
            text-overflow: ellipsis;
            padding: 5px 10px;
            margin: 0;
          }
        }

        .row {
          &__element {
            position: relative;
            cursor: default;
            min-height: 100%;

            span, p {
              cursor: default;
            }

            &:hover {
              p {
                display: block;
                max-height: 100%;
              }
            }

            &_short {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;

              &_container {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }

            &_full {
              display: none;
              position: absolute;
              min-width: 100%;
              min-height: 100%;
              left: 0;
              top: 0;
              z-index: 2;

              span {
                min-width: 100%;
                min-height: 30.5px;
                padding: 5px 10px;
                margin-left: -1px;
                margin-right: -1px;
                display: block;
                background-color: var(--card-bg);
                border-bottom: 1px solid var(--card-header-color);
                border-right: 1px solid var(--card-header-color);
                border-left: 1px solid var(--card-header-color);
              }

              &:hover {
                display: block;
              }
            }
          }

          &__timestamp {
            width: 120px;
          }
          &__requestID {
            width: 140px;
          }
          &__projectID {
            width: 140px;
          }
          &__network {
            width: 122px;
          }
          &__responseTime {
            width: 110px;
          }
          &__eventType {
            width: 90px;
          }
          &__query {
            width: 340px;
          }
          &__httpStatus {
            width: 95px;
          }
          &__errCode {
            width: 80px;
          }
          &__errMessage {
            width: 120px;
          }
          &__bytes {
            width: 60px;
          }
        }
      }

      &__noRequests {
        position: sticky;
        left: 50%;
        transform: translateX(-50%);

        width: min-content;

        white-space: nowrap;
        color: #aaa;
        font-size: 18px;

        padding: 20px;
      }
    }
  }

  &__pagination {
    display: flex;
    justify-content: center;
  }
}
