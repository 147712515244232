@mixin LightTheme {
  .LightTheme {
    @content
  }
}

@mixin DarkTheme {
  .DarkTheme {
    @content
  }
}

@mixin desktopWidthAndUp {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin tabletWidthAndUp {
  @media (min-width: 700px) {
    @content;
  }
}

@mixin tabletWidthAndDown {
  @media (max-width: 700px) {
    @content;
  }
}

@mixin mobileWidthAndDown {
  @media (max-width: 450px) {
    @content;
  }
}
