:root {
    --dashboard-filter-radio: #1976d2;
}

.light-theme {
    --base-color: gray;
    --body-bg: white;
    --text-color: #444;
    --title-color: #333;
    --link-color: #2ac2e4;

    --code-bg: #2d1e2f;
    --code-color: Lavender;
    --footer-bg: #aaa;
    --footer-color: #666;

    --card-bg: white;
    --separator-border: 1px solid #ccc;

    --card-list-selected-item-bg: #eee;
    --card-list-selected-item-color: #555;
    --card-header-color: #444;
    --card-header-color-active: #1a1a1a;
    --card-footer-border-color: #ddd;

    --tab-selected-line-color: #999;
    --tab-selected-color: #333;

    --form-field-bg: white;
    --form-field-border-color: #ddd;
    --form-field-color: #333;

    --dashboard-logs-header: #f0f0f0;
    --dashboard-filter-title: #847d7d;

    background: #f6f6f6;
}

.dark-theme {
    --base-color: black;
    --body-bg: black;
    --text-color: #999;
    --title-color: #999;
    --link-color: #2ac2e4;
    --footer-bg: #111;
    --footer-color: #666;

    --code-bg: #2d1e2f;
    --code-color: Lavender;

    --card-bg: #111;
    --separator-border: 1px solid #222;

    --card-list-selected-item-bg: #333;
    --card-list-selected-item-color: #aaa;
    --card-header-color: #aaa;
    --card-header-color-active: Lavender;
    --card-footer-border-color: #333;

    --tab-selected-line-color: #999;
    --tab-selected-color: #999;

    --form-field-bg: #222222;
    --form-field-border-color: #333;
    --form-field-color: #eee;

    --dashboard-logs-header: #333333;
    --dashboard-filter-title: #847d7d;

    background: black;
}
