@import "src/styles/mixins";

.Register {
  &_main {
    @include tabletWidthAndDown {
      width: 80%;
      position: relative !important;
      transform: translateX(-50%) translateY(0) !important;
      top: 0 !important;
    }

    @include mobileWidthAndDown {
      width: 95%;
    }
  }

  &_fieldRow {
    @include tabletWidthAndDown {
      flex-direction: column;
    }
  }
}
