@import "src/styles/mixins";

.AccountSideMenu {
  &__logoutButton {
    width: 180px;
    align-self: center;

    @include tabletWidthAndUp {
      width: auto;
      align-self: stretch;
    }
  }
}
