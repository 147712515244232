.flex {
  display: flex;
}

.directionColumn {
  flex-direction: column;
}

.justifyContentSpaceBetween {
  justify-content: space-between;
}
