.Usage {
  max-width: 1320px;
  width: 100%;
  padding: 0 12px;
  &__banner {
    color: #fff;
    height: 240px;
    max-width: 500px;
    min-width: 250px;
    border-radius: 8px;
    padding: 24px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: linear-gradient(267.45deg, #f8ba3c -34.23%, #ff8723 99.39%);

    &__currentMonth {
      text-transform: uppercase;
      align-self: flex-start;
      font-size: 13px;
      font-weight: 500;
    }

    &__amountValue {
      letter-spacing: 0.5px;
      font-size: 36px;
    }

    &__label {
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: #fff;
      font-size: 10px;
    }
  }

  &__title {
    color: var(--card-header-color);
    font-size: 24px;
    margin: 20px 0 8px 0 !important;
  }
}
