@import "src/styles/mixins";

.Dashboard {
  &__main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;

    @include tabletWidthAndUp {
      flex-direction: row;
    }

    .main {
      &__aside {
        width: 100%;

        &_left {
          display: flex;
          flex-wrap: wrap;
          gap: 20px;

          height: max-content;

          width: 250px;

          & > * {
            flex: 1 0 190px;

            max-width: 100%;

            overflow: hidden;
          }
        }

        &_right {
          display: none;
          width: 400px;

          @include desktopWidthAndUp {
            display: block;
          }
        }
      }

      &__content {
        overflow: hidden;

        @include tabletWidthAndUp {
          flex: 1 1 400px;
          max-width: 650px;
        }
      }
    }
  }
}
