.Project {
  &__summary {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(calc(300px), 1fr));
    gap: 15px;
    background: linear-gradient(267.45deg, #f8ba3c -34.23%, #ff8723 99.39%);
    border-radius: 10px;
    padding: 15px;

    .summary__card {
      display: flex;
      flex-direction: column;
      flex: 25% 0 0;
      color: var(--dashboard-logs-header);
      text-transform: uppercase;
      background-color: #ffffff0d;
      padding: 20px 20px 10px;
      border-color: #fff3;
      border-style: solid;
      border-width: 1px;
      border-radius: 5px;

      .card {
        &__title {
          margin: 0 0 10px;
          font-size: 14px;
          font-weight: 400;
        }

        &__value {
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 50px;
          font-weight: 300;
          margin: auto 0 0;
          overflow: hidden;
        }
      }
    }
  }
}
